// @flow

import * as React from 'react';
import { marked } from 'marked';
import { sanitizeHtml } from '@realadvisor/html-sanitizer';

type Props = {|
  children: string,
  links?: boolean,
|};

const sanitizeBacktick = text => text.replace(/`/g, '&#x60;');

const sanitizeLinebreak = text => text.replace(/(\r\n|\n)/g, '<br />');

export const InlineMarked = ({ children, links }: Props): React.Node => {
  const html = React.useMemo(() => {
    const renderer = new marked.Renderer();
    renderer.paragraph = text => text;
    if (links === false) {
      renderer.link = (href, title, text) => text;
    }
    return sanitizeHtml(
      marked(sanitizeBacktick(sanitizeLinebreak(children)), {
        renderer,
        breaks: true,
      }),
    );
  }, [children, links]);
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export const Marked = ({ children, links }: Props): React.Node => {
  const html = React.useMemo(() => {
    const renderer = new marked.Renderer();
    if (links === false) {
      renderer.link = (href, title, text) => text;
    }
    return sanitizeHtml(
      marked(sanitizeBacktick(children), {
        renderer,
        breaks: true,
      }),
    );
  }, [children, links]);
  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export const getTextOfMarkdown = (input: string): string => {
  const renderer = new marked.Renderer();

  renderer.code = code => code + '\n';
  renderer.blockquote = quote => quote + '\n';
  renderer.heading = text => text + '\n';
  renderer.hr = () => '\n';
  renderer.list = text => text + '\n';
  renderer.listitem = text => text + '\n';
  renderer.checkbox = () => '';
  renderer.paragraph = text => text + '\n';
  renderer.table = (header, body) => `${header}\n${body}\n`;
  renderer.tablerow = text => text + '\n';
  renderer.tablecell = text => text + ' ';
  renderer.strong = text => text;
  renderer.em = text => text;
  renderer.codespan = text => text;
  renderer.br = () => '\n';
  renderer.del = text => text;
  renderer.link = (href, title, text) => text;
  renderer.image = () => '';

  return marked(sanitizeBacktick(input), {
    renderer,
    breaks: true,
  });
};
